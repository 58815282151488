<script setup lang="ts">
const { lease } = useCurrentLeaseDocument()

const progress = computed(() => {
  if (!lease.value.summary.approvedFields || !lease.value.summary.totalFields)
    return 0
  return (
    (lease.value.summary.approvedFields / lease.value.summary.totalFields) * 100
  )
})
</script>

<template>
  <LeaseCompletion
    v-if="lease.summary.approvedFields && lease.summary.totalFields"
    :title="`${lease.summary.approvedFields}/${lease.summary.totalFields}`"
    :progress="progress"
    text-alignment="text-right"
    :tooltip="{ placement: 'left' }"
  />
</template>
